import { getContact, GetContactResult } from 'application/adapters/contacts/contactsAdapter';
import { getCMSHostname } from 'helpers/getCMSHostname';
import { useEffect, useState } from 'react';
import { Contact } from 'ui/components/3-organisms/Contact';

export const ContactFeature: React.FC<Umbraco.Contact> = ({ content }) => {
	const [departments, setDepartments] = useState<GetContactResult>(null);

	// Check if default branch matches any branches in the list and pass that as default value
	const defaultValue = departments?.branches?.find(function (element) {
		return element?.value === departments?.defaultBranch;
	});

	useEffect(() => {
		getContact(getCMSHostname())
			.then((response) => {
				setDepartments(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return <Contact departments={departments} selectValue={defaultValue?.value} title={content.properties.title} />;
};
