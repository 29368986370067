import { Profile } from 'ui/components';
import React, { useContext, useEffect, useState } from 'react';
import { Profiles } from 'application/repositories/profilesRepository';
import { ProfileContext } from 'application/adapters/context/Profile';

export const ProfileFeature: React.FC<Umbraco.Profile> = ({ content }) => {
	const [profiles, setProfiles] = useState<Profiles>(null);

	const profileContext = useContext(ProfileContext);

	const profile = profileContext.profile;


	useEffect(() => {
		if (profile) {
			setProfiles(profile);
		}
	}, [profile]);

	return <Profile {...content?.properties} profiles={profiles} />;
};
