import { postPayment } from 'application/adapters/payment/paymentAdapter';
import { useState } from 'react';
import { getCMSHostname } from 'helpers/getCMSHostname';
import { Payment } from 'ui/components/3-organisms/Payment';
import logger from 'helpers/logger';
export const PaymentFeature: React.FC<Umbraco.Payment> = ({ content }) => {
	const [isSuccess, setSuccess] = useState(false);
	const [isFail, setFail] = useState(false);
	const [cprIsFail, setCprIsfail] = useState(false);
	const [NameIsFail, setNameIsfail] = useState(false);
	const [AmountIsFail, setAmountIsfail] = useState(false);
	const [isLoading, setLoading] = useState(true);
	const submitForm = (data) => {
		setLoading(false);
		postPayment(getCMSHostname(), data)
			.then((response) => {
				if (response.status == 200) {
					window.location.href = content.properties.checkoutLink.url + '?paymentId=' + response.data
					setTimeout(() => {
						
						setSuccess(!isSuccess);
					}, 50);
				}
			})
			.catch((error) => {

				
				const statusCode = error.response.status;
				const responsedata = error.response.data;
				if (statusCode == 400){
				if (responsedata.includes('CPR')) {
					setCprIsfail(true);

				} else 
				{
					setCprIsfail(false);

				}
				if (responsedata.includes('name')) {
					setNameIsfail(true);

				} else 
				{
					setNameIsfail(false);
				}
				if (responsedata.includes('amount')) {
					setAmountIsfail(true);

				} else {
					setAmountIsfail(false);
				}

			} 
			else {

				
				setFail(!isFail);
			}
				logger.error(error);

			})
			.finally(() => {
				setLoading(true);
			});
	};
	return <Payment {...content?.properties} isSuccess={isSuccess} AmountIsFail={AmountIsFail} NameIsFail={NameIsFail} cprIsFail={cprIsFail} isFail={isFail} onClick={submitForm} isLoading={isLoading} />;
};
