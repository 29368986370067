import logger from 'helpers/logger';
import { capitalizeFirstLetter } from 'helpers/string';
import * as PageTypes from 'features/PageTypes';
import { PageContentResponse } from 'application/repositories/pageContentRepository';

export const PageTypeMapFeature: React.FC<PageContentResponse['page']> = ({ template, ...page }) => {
	const production = process.env.NODE_ENV === 'production';

	const featureName = `${capitalizeFirstLetter(template)}PageFeature`;
	const DynamicPageTemplate = PageTypes[featureName];

	if (!DynamicPageTemplate) {
		const errorMessage = `PageType [${template}] is missing a React Component. Add missing export '${featureName}' from /features`;
		logger.error(errorMessage);
		return production ? null : <div>{errorMessage}</div>;
	}

	return <DynamicPageTemplate {...page} />;
};
