import { ListVisitCard } from 'ui/components';
import React, { useContext, useEffect, useState } from 'react';
import { Profiles } from 'application/repositories/profilesRepository';
import { ProfileContext } from 'application/adapters/context/Profile';

export interface ListVisitCard {
	documentType: 'listVisitCard';
	content: {
		documentType: 'listVisitCard';
		properties: {
			heading: string;
			notificationText: string;
		};
	};
}
export const ListVisitCardFeature: React.FC<ListVisitCard> = ({ content }) => {
	const { heading, notificationText } = content.properties;
	const [profiles, setProfiles] = useState<Profiles>(null);

	const profileContext = useContext(ProfileContext);
	const profile = profileContext.profile;
	useEffect(() => {
		if (profile) {
			setProfiles(profile);
		}
	}, [profile]);

	return <ListVisitCard profiles={profiles} heading={heading} notificationText={notificationText} />;
};
