import axios, { AxiosResponse } from 'axios';
import { getAccessTokenBrowser } from 'helpers/auth';
import { postApiUrl } from './constants';

export type PostFeedbackResult = Record<string, never>;

export const postFeedback = async (hostname: string, data: string): Promise<AxiosResponse<PostFeedbackResult>> => {
	const accessToken = await getAccessTokenBrowser();
	const { href } = postApiUrl(`feedback/Send`);
	return axios.post<PostFeedbackResult>(href, data, {
		headers: { hostname, Authorization: 'Bearer ' + accessToken, 'Content-Type': 'application/json' },
	});
};
