import { Slider } from 'ui/components/3-organisms/Slider';
import { SliderItemProps } from 'ui/components/3-organisms/Slider/SliderItem/SliderItem';
import { Container } from 'ui/components';

export const SliderFeature: React.FC<Umbraco.Slider> = ({ content }) => {
	const { slides, sliderHeading, styleRevert } = content?.properties ?? {};

	const mappedSlides: SliderItemProps[] = slides.map(({ content }) => {
		const { heading, text, image, link } = content?.properties ?? {};

		return {
			heading,
			text,
			image,
			link,
		};
	});

	return (
		<Container width="Standard">
			<Slider sliderHeading={sliderHeading} slides={mappedSlides} styleRevert={styleRevert} />
		</Container>
	);
};
