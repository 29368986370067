import { Main } from 'ui/components';

import { ModuleMapFeature } from 'features/Mappers/Module';

export const ContentPageFeature: React.FC<Umbraco.ContentPage> = ({ properties }) => {
	const { pageHeader, pageSections } = properties ?? {};

	const IsHeaderBanner = pageHeader[0]?.content?.documentType?.toString() === 'topBanner';
	return (
		<Main
			header={pageHeader?.map((headerModule, index) => (
				<ModuleMapFeature key={index} contentModule={headerModule} />
			))}
			isHeaderBanner={IsHeaderBanner}
		>
			{pageSections?.map((sectionModule, index) => (
				<ModuleMapFeature key={index} contentModule={sectionModule} />
			))}
			
		</Main>
	);
};
