import { SiteContext } from 'application/adapters/context/SiteContext';
import { useContext } from 'react';
import { Map } from 'ui/components/3-organisms/Map';
import logger from 'helpers/logger';

export const MapFeature: React.FC<Umbraco.Map> = ({ content, settings }) => {
	const { mapMarkers } = content?.properties ?? {};

	const siteSettings = useContext(SiteContext);
	const { apiKey } = siteSettings.getSettings('mapsSettings') ?? {};

	if (!apiKey) {
		logger.error('[MapFeature] Maps API key is not set');
		return null;
	}

	const mappedMappedMarkers: Models.MapsMarker[] = mapMarkers.map(
		(marker) => ({ ...marker?.content?.properties }),
	);

	return (
		<Map
			mapMarkers={mappedMappedMarkers}
			apiKey={apiKey}
			{...settings?.properties}
		/>
	);
};
