import useSWR from 'swr';
import { getSearchResults } from 'application/repositories/searchRepository';

export const getBlurbCardFromSearchResult = (item: Models.SearchResult): Models.BlurbCard => {
	const { link, date, teaser, title } = item ?? {};

	return {
		link,
		date: new Date(date),
		heading: title,
		kicker: teaser,
		settings: {
			properties: {
				desktopWidth: '100',
				mobileWidth: '100',
			},
		},
	};
};

interface SearchResultFetcherResponse {
	isLoading: boolean;
	error: string;
	searchResult: {
		numberOfResults: number;
		query: string;
		results: Models.BlurbCard[];
	};
}
export const SearchResultFetcher = (searchQuery: string): SearchResultFetcherResponse => {
	const shouldSearch = searchQuery !== '';
	const { data, error } = useSWR(shouldSearch ? [searchQuery] : null, getSearchResults);
	const { numberOfResults, query, searchResults = [] } = data ?? {};

	const mappedResults: Models.BlurbCard[] = searchResults.map((result) => getBlurbCardFromSearchResult(result));

	return {
		isLoading: shouldSearch && !data && !error,
		error: error,
		searchResult: {
			numberOfResults: numberOfResults,
			query: query,
			results: mappedResults,
		},
	};
};
