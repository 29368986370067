import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { postFeedback, PostFeedbackResult } from 'application/adapters/feedback/feedbackAdapter';
import { getCMSHostname } from 'helpers/getCMSHostname';
import styles from './Feedback.module.scss';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Form, Container, Button, Paragraph, Heading, Icon, GridCell, Spinner } from 'ui/components';
import { ReactComponent as FeedbackIcon } from 'ui/icons/icon-feedback.svg';
import { ReactComponent as Success } from 'ui/icons/icon-success.svg';
import { ReactComponent as Fail } from 'ui/icons/icon-fail.svg';
import axios from 'axios';
import logger from 'helpers/logger';

export interface formData {
	textareaContent: string;
	urlLocation: string;
}
export interface FeedbackProps {
	className?: string;
	heading?: string;
	subHeading?: string;
	inputHeading?: string;
	submitText: string;
	placeholderText?: string;
	submitValidation: string;
	graphicText?: string;
	successMessage: string;
	failMessage: string;
	isSuccess: boolean;
	isFail: boolean;
	isLoading: boolean;
	onClick: (data: formData) => void;
}

export const Feedback: React.FC<FeedbackProps> = ({
	className,
	heading,
	subHeading,
	inputHeading,
	submitText,
	placeholderText,
	submitValidation,
	graphicText,
	successMessage,
	failMessage,
	isSuccess,
	isFail,
	isLoading,
	onClick,
}) => {
	const [value, setValue] = useState('');
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<formData>();
	const onSubmit: SubmitHandler<formData> = (data) => {
		onClick(data);
		setValue('');
	};

	return (
		<Container width="Standard" className={classNames(styles.Feedback, className)}>
			<GridCell>
				<Heading headingLevel="h3" className={styles.Feedback_heading}>
					{heading}
				</Heading>
				<Paragraph className={styles.Feedback_subHeading}>{subHeading}</Paragraph>

				<article
					className={classNames(
						styles.Feedback_formWrapper,
						{
							[styles.Feedback_formWrapper___sucess]: isSuccess,
							[styles.Feedback_formWrapper___fail]: isFail,
						},
						className,
					)}
				>
					<Form className={styles.Feedback_form} onSubmit={handleSubmit(onSubmit)}>
						<Paragraph className={styles.Feedback_inputHeading}>{inputHeading}</Paragraph>
						<textarea
							id="textarea"
							placeholder={placeholderText}
							className={styles.Feedback_textarea}
							{...register('textareaContent', { required: true })}
							value={value}
							onChange={(e) => {
								setValue(e.target.value);
							}}
						/>
						<input
							type="hidden"
							name="url"
							value={typeof window !== 'undefined' ? window?.location?.href : ''}
							{...register('urlLocation', { required: true })}
						/>
						{isSuccess && (
							<span className={styles.Feedback_successText}>
								<Icon className={styles.Feedback__messageIcon}>
									<Success />
								</Icon>
								{successMessage}
							</span>
						)}

						{isFail && (
							<span className={styles.Feedback_failText}>
								<Icon className={styles.Feedback__messageIcon}>
									<Fail />
								</Icon>
								{failMessage}
							</span>
						)}
						{errors.textareaContent && <span className={styles.Feedback_failText}>{submitValidation}</span>}
						<Button className={styles.Feedback_button} style="primary" type="submit" aria-label="Send">
							{!isLoading ? <Spinner className={styles.Feedback_spinner} /> : submitText}
						</Button>
					</Form>
					<aside className={styles.Feedback_graphicWrapper}>
						<Icon size="md" className={styles.Feedback_graphic}>
							<FeedbackIcon />
						</Icon>
						<Paragraph className={styles.Feedback_graphicText}>{graphicText}</Paragraph>
					</aside>
				</article>
			</GridCell>
		</Container>
	);
};
