import { getCMSHostname } from 'helpers/getCMSHostname';
import { PaymentCompleted } from 'ui/components/3-organisms/PaymentCompleted';
import { getPaymentdetails } from 'application/repositories/paymentCompletedRepository';

import { useEffect, useState } from 'react';
import logger from 'helpers/logger';
export const PaymentCompletedFeature: React.FC<Umbraco.PaymentCompleted> = ({ content }) => {

	const [paymentDetails, setPaymentDetails] = useState<any>(null);

	useEffect(() => {
	  const urlParams = new URLSearchParams(window.location.search);
	  const paymentId = urlParams.get('paymentId');
  
	  getPaymentdetails(getCMSHostname(), paymentId)
		.then((details) => {
		  // Set the payment details in the state
		  setPaymentDetails(details);
		})
		.catch((error) => {
		  // Handle error if necessary
		  logger.error(error);
		});
	}, []);

	return <PaymentCompleted {...content?.properties} customerName={paymentDetails?.name} amount={paymentDetails?.amount} />;
};
