import { SuperHeroCalendarCard } from 'ui/components';

export const SuperHeroCalendarCardFeature: React.FC<Umbraco.SuperHeroCalendarCard> = ({ content }) => {
	const { backgroundImage, heading, module, text } = content?.properties ?? {};
	const { accordionItems } = module[0]?.content?.properties ?? {};

	const tabItems = accordionItems.map((items) => {
		const { accordionItems, title, tabSubTitle } = items?.content?.properties ?? {};
		return {
			title,
			tabSubTitle,
			items: accordionItems.map((item) => {
				const { htmlContent, image, imageName, imageTitle, subTitle, title } = item?.content?.properties ?? {};
				return {
					htmlContent,
					image,
					imageName,
					imageTitle,
					subTitle,
					title,
				};
			}),
		};
	});

	return <SuperHeroCalendarCard heading={heading} text={text} backgroundImage={backgroundImage} module={tabItems} />;
};
