import axios, { AxiosResponse } from 'axios';
import { getAccessTokenBrowser } from 'helpers/auth';
import { getApiUrl } from './constants';

export type GetAgreementDocumentsResult = {
	title: string;
	teaser: string;
	documents: Array<{
		id: string;
		title: string;
		teaser: string;
		chapters: Array<{
			id: string;
			title: string;
			sections: [
				{
					id: string;
					title: string;
				},
			];
		}>;
	}>;
};

export type RequestHeaders = {
	hostname: string;
	Authorization?: string;
};

export const getAgreementDocuments = async (hostname: string, agreementId: number): Promise<AxiosResponse<GetAgreementDocumentsResult>> => {
	const accessToken = await getAccessTokenBrowser();
	const headers: RequestHeaders = {
		hostname,
	};
	if (accessToken) {
		headers.Authorization = 'Bearer ' + accessToken;
	}
	const { href } = getApiUrl(`Agreement/GetAgreementDocuments/${agreementId}`);
	return axios.get<GetAgreementDocumentsResult>(href, { headers: headers });
};

export type GetAgreementDocumentSectionsResult = {
	id: string;
	title: string;
	sectionTexts: [
		{
			html: string;
		},
	];
};
export const getAgreementDocumentSections = async (
	hostname: string,
	sectionId: string,
): Promise<AxiosResponse<GetAgreementDocumentSectionsResult>> => {
	const accessToken = await getAccessTokenBrowser();
	const headers: RequestHeaders = {
		hostname,
	};
	if (accessToken) {
		headers.Authorization = 'Bearer ' + accessToken;
	}
	const { href } = getApiUrl(`Agreement/GetAgreementDocumentSections/${sectionId}`);
	return axios.get<GetAgreementDocumentSectionsResult>(href, { headers: headers });
};

export type GetAgreementFilesResult = {
	agreementFiles: {
		items: [
			{
				url: string;
				name: string;
				fileType: string;
			},
		];
	};
	agreementLink: {
		link: {
			url: string;
			name: string;
		};
	};
};
export const getAgreementFiles = async (hostname: string, agreementId: number): Promise<AxiosResponse<GetAgreementFilesResult>> => {
	const accessToken = await getAccessTokenBrowser();
	const headers: RequestHeaders = {
		hostname,
	};
	if (accessToken) {
		headers.Authorization = 'Bearer ' + accessToken;
	}
	const { href } = getApiUrl(`Agreement/GetAgreementFiles/${agreementId}`);
	return axios.get<GetAgreementFilesResult>(href, { headers: headers });
};
export const getAllAgreementFiles = async (hostname: string): Promise<AxiosResponse<GetAgreementFilesResult>> => {
	const accessToken = await getAccessTokenBrowser();
	const headers: RequestHeaders = {
		hostname,
	};
	if (accessToken) {
		headers.Authorization = 'Bearer ' + accessToken;
	}
	const { href } = getApiUrl(`Agreement/GetAllAgreementFiles`);
	return axios.get<GetAgreementFilesResult>(href, { headers: headers });
};
