import {  getPaymentAdapter } from 'application/adapters/paymentCompleted/paymentCompletedAdapter';

export type paymentCompletedResult = {
	name: string;
	amount: number;

};

export const getPaymentdetails = async (hostname: string,paymentId : string): Promise<paymentCompletedResult> => {
	const payment = await getPaymentAdapter(hostname,paymentId);
	return payment;
};


