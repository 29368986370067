import { ApiError, ApiRedirect } from 'helpers/error';
import axios from 'axios';
import { SearchResultResponse } from 'application/repositories/searchRepository';

export interface RequestInterface {
	query: string;
}

const SEARCH_API_URL = process.env.NEXT_PUBLIC_SEARCH_API_URL?.endsWith('/')
	? process.env.NEXT_PUBLIC_SEARCH_API_URL
	: `${process.env.NEXT_PUBLIC_SEARCH_API_URL}/`;

export const searchAdapter = ({ query }: RequestInterface): Promise<SearchResultResponse> => {
	if (!SEARCH_API_URL) {
		throw new ApiError('SEARCH_API_URL:NOTDEFINED', 500, 'Search api url is not defined');
	}

	return axios({
		url: `${SEARCH_API_URL}?q=${query}`,
		method: 'GET',
		maxRedirects: 0,
	})
		.then((res) => {
			const { data } = res ?? {};

			return data;
		})
		.catch((error) => {
			const message = error?.response?.data?.message ?? error;
			const statusCode = error?.response?.status || 500;

			if (statusCode === 301 || statusCode === 302) {
				throw new ApiRedirect(statusCode, error.response.data.url);
			}

			throw new ApiError('Search API error', statusCode, message);
		});
};
