import { getAgreementFiles, GetAgreementFilesResult } from 'application/adapters/agreements/agreementsAdapter';
import { getCMSHostname } from 'helpers/getCMSHostname';
import logger from 'helpers/logger';
import React, { useEffect, useState } from 'react';
import { DownloadBarContainer } from 'ui/components/3-organisms/DownloadBarContainer';

type DownloadBarContainer = {
	documentType: 'downloadBarContainer';
	content: {
		documentType: 'downloadBarContainer';
		properties: {
			link: Umbraco.Link;
			downloadText: string;
			agreementItem: {
				id: number;
				culture: string;
				name: string;
				url: string;
				documentType: string;
				template: string;
				createDate: string;
				sortOrder: string;
			};
		};
	};
};
export const DownloadBarContainerFeature: React.FC<DownloadBarContainer> = ({ content }) => {
	const agreementId = content.properties.agreementItem.id;

	const [agreementFiles, setAgreementFiles] = useState<GetAgreementFilesResult>(null);

	useEffect(() => {
		getAgreementFiles(getCMSHostname(), agreementId)
			.then((response) => {
				setAgreementFiles(response.data);
			})
			.catch((error) => {
				logger.error(error);
			});
	}, [agreementId]);

	return <DownloadBarContainer {...content?.properties} agreementFiles={agreementFiles} />;
};
