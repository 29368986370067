import { ApiError, ApiRedirect } from 'helpers/error';
import axios from 'axios';

export interface NewsQuery {
	rootId: number;
	limit: number;
	offset: number;
	preview: boolean;
	previewData: Models.PreviewData;
}

export interface NewsArticle {
	heading?: string;
	url: string;
	session?: string;
	kicker?: string;
	text?: string;
	date?: Date;
	updatedDate?: Date;
	image: Umbraco.Image;
}

export interface NewsResponse {
	articles: NewsArticle[];
}

export const getNews = async (query: NewsQuery): Promise<NewsResponse> => {
	const rootIdQuery = query.rootId ? `rootIds:[${query.rootId}]` : '';
	const limitQuery = query.limit !== null && query.limit > 0 ? `limit:${query.limit}` : '';
	const offsetQuery = query.offset !== null && query.offset > 0 ? `offset:${query.offset}` : '';

	const graphQLquery = `{
		pageSearch(documentType:["newsPage"] ${rootIdQuery} ${limitQuery} ${offsetQuery})
			{
				pages {
					name
					url
					id
					properties
				}
			}
		}`;

	const headers = {};

	return axios({
		url: `/api/graphQLContent`,
		method: 'POST',
		headers: headers,
		data: {
			query: graphQLquery,
			preview: query.preview,
			previewData: query.previewData,
		},
	})
		.then((res) => {
			const newsPages: Umbraco.NewsPage[] = res.data?.pageSearch?.pages;
			if (!newsPages) return null;
			return {
				articles: getArticles(newsPages),
			};
		})
		.catch((error) => {
			const message = error?.response?.data?.message ?? error;
			const statusCode = error?.response?.status || 500;

			if (statusCode === 301 || statusCode === 302) {
				throw new ApiRedirect(statusCode, error.response.data.url);
			}

			throw new ApiError('News API error', statusCode, message);
		});
};

function getArticles(newsPages: Umbraco.NewsPage[]): NewsArticle[] {
	return newsPages.map((page) => ({
		heading: page.properties.newsHeading,
		url: page.url,
		session: page.properties.session,
		kicker: page.properties.newsKicker,
		text: page.properties.newsTeaser,
		date: new Date(page.properties.newsDate),
		updatedDate: new Date(page.properties.updatedNewsDate),
		image: page.properties.image,
	}));
}
