import { Member, getMember } from 'application/repositories/membersRepository';
import { getCMSHostname } from 'helpers/getCMSHostname';
import { useState, useEffect } from 'react';
import { ProfileCard } from 'ui/components/2-molecules/Card/ProfileCard';

export const ProfileCardFeature: React.FC<Umbraco.ProfileCard> = ({ content }) => {
	const [member, setMember] = useState<Member>(null);

	useEffect(() => {
		getMember(getCMSHostname())
			.then((member) => {
				setMember(member);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return (
		<ProfileCard
			{...content?.properties}
			profileName={member.name}
			profileTitle={member.job}
			profilePosition={member.chairedTitles[0].chairedTitle}
			profilePositionSecondary={member.chairedTitles[1].chairedTitle}
		/>
	);
};
