import { WelcomeCardProfile } from 'ui/components';
import { getCMSHostname } from 'helpers/getCMSHostname';
import { useState, useEffect, useContext } from 'react';
import { Profiles } from 'application/repositories/profilesRepository';
import { getSignedUpArrangements, Arrangement } from 'application/repositories/arrangementsRepository';
import logger from 'helpers/logger';
import { ProfileContext } from 'application/adapters/context/Profile';


import { GetContactResult,getContact } from 'application/adapters/contacts/contactsAdapter';

export const WelcomeCardProfileFeature: React.FC<Umbraco.WelcomeCardProfile> = ({ content }) => {
	const [profiles, setProfiles] = useState<Profiles>(null);
	const [arrangements, setArrangements] = useState<Arrangement[]>([]);

	useEffect(() => {
		getSignedUpArrangements(getCMSHostname())
			.then((arrangements) => {
				arrangements?.length > 0 && setArrangements(arrangements.slice(0, 2));
			})
			.catch((error) => {
				logger.error(error);
			});
	}, []);

	const profileContext = useContext(ProfileContext);

	const profile = profileContext.profile;
	const departments = profileContext.branches;
	useEffect(() => {
		if (profile) {
			setProfiles(profile);
		}
	}, [profile]);

	

	const myBranch = departments?.branches && profile?.branch?.name ?  departments?.branches.find(x => x.value == profile?.branch?.name) : null
	const branchUrl = myBranch?.memberPortalUrl;

	return <WelcomeCardProfile {...content?.properties} profiles={profiles} arrangements={arrangements}  branchUrl={branchUrl}/>;
};
