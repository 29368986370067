import axios, { AxiosResponse } from 'axios';
import { getAccessTokenBrowser } from 'helpers/auth';
import { getApiUrl } from 'helpers/getApiUrl';
export type PostPaymentResult = Record<string, never>;

export type RequestHeaders = {
	hostname: string;
	Authorization?: string;
};


export type paymentCompletedResult = {
	name: string;
	amount: number;

};


export const getPaymentAdapter = async (hostname: string,paymentId: string): Promise<paymentCompletedResult> => {

	const queryParam: Record<string, string> = {
		paymentId: paymentId,
	  };
	const accessToken = await getAccessTokenBrowser();
	const headers: RequestHeaders = {
		hostname,
	};
	if (accessToken) {
		headers.Authorization = 'Bearer ' + accessToken;
	}


	const { href } = getApiUrl(`NetsPayment/GetPayment`);
	const { data: news } = await axios.get<paymentCompletedResult>(href, { headers: headers,params:queryParam });
	return news;
};