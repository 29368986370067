import { useContext, useEffect, useState } from 'react';
import { HeaderPortal } from 'ui/components/4-habitats/HeaderPortal';
import { SiteContext } from 'application/adapters/context/SiteContext';
import { PageContext } from 'application/adapters/context/PageContext';
import { getMember, Member } from 'application/repositories/membersRepository';
import { getCMSHostname } from 'helpers/getCMSHostname';

export const HeaderPortalFeature: React.FC = () => {
	const [member, setMember] = useState<Member>(null);
	const siteContext = useContext(SiteContext);
	const pageContext = useContext(PageContext);

	const navigationItemsPortal = siteContext.getNavigationItems(pageContext.id);

	useEffect(() => {
		getMember(getCMSHostname())
			.then((member) => {
				setMember(member);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return <HeaderPortal navigationItemsPortal={navigationItemsPortal} member={member} />;
};
