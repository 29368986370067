import classNames from 'classnames';
import { useRef, useState, useContext, useEffect } from 'react';
import { AppStateContext } from 'application/adapters/appState/appState';
import {
	Container,
	Grid,
	GridCell,
	Icon,
	LanguageBar,
	Link,
	LinkButton,
	Logo,
	NavigationDef,
	ServiceNavigation,
	SkipToMain,
	Button,
	SearchCludo,
} from 'ui/components';
import { ReactComponent as IconLock } from 'ui/icons/icon-lock.svg';
import { ReactComponent as IconLoop } from 'ui/icons/icon-loop.svg';
import styles from './HeaderDef.module.scss';
import { signOut, useSession } from 'next-auth/react';

export interface HeaderDefProps {
	className?: string;
	navHeading?: string;
	serviceNavigationItems?: Models.ServiceNavigation;
	languageBarItems?: Models.LanguageBar;
	languageBarTitle?: Models.LanguageBar;
	navigationItemsDef?: Models.NavigationItem[];
	registerLink?: Models.RegisterBar;
}

export type navState = boolean;

export const HeaderDef: React.FC<HeaderDefProps> = ({
	className,
	navHeading,
	navigationItemsDef,
	serviceNavigationItems,
	languageBarItems,
	languageBarTitle,
	registerLink,
}) => {
	const [navOpen, setNavOpen] = useState<navState>(false);
	const checkboxRef = useRef(null);
	const [searchOpen, setSearchOpen] = useState(false);
	const headerRef = useRef(null);
	const handleKeyboardNav = (keyDownEvent) => {
		keyDownEvent.preventDefault();
		if (keyDownEvent.keyCode === 32) {
			if (!checkboxRef.current.checked) {
				checkboxRef.current.checked = true;
				setNavOpen(true);
			} else {
				checkboxRef.current.checked = false;
				setNavOpen(false);
			}
		}
	};

	const searchClick = () => {
		setSearchOpen(!searchOpen);
	};
	const handleClickClose = (event) => {
		const element = headerRef.current;
		if (!element?.contains(event.target)) {
			setSearchOpen(false);
		}
	};
	const handleCloseSearch = () => {
		setSearchOpen(false);
	};
	const handleOpenSearch = () => {
		setSearchOpen(!searchOpen);
	};
	useEffect(() => {
		if (searchOpen) {
			document.addEventListener('click', handleClickClose);
		}
		return () => document.removeEventListener('click', handleClickClose);
	}, [searchOpen]);

	const { status } = useSession();
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	useEffect(() => {
		if (status === 'authenticated') {
			setIsLoggedIn(true);
		} else {
			setIsLoggedIn(false);
		}
	}, [status]);

	const signOutClick = () => {
		signOut({
			redirect: false,
		}).then(() => {
			const redirectUrl = registerLink?.logOutLink?.url ?? window.location.href;
			window.location.href = `https://${process.env.NEXT_PUBLIC_AUTHENTICATION_DOMAIN}/account/logout?returnUrl=${redirectUrl}`;
		});
	};

	const { toggleShowLoginModal } = useContext(AppStateContext);
	return (
		<header ref={headerRef} className={classNames(styles.HeaderDef, navOpen && styles.HeaderDef___active)} role="banner" aria-expanded={searchOpen}>
			<SkipToMain link="#main" title="Click to skip navigations and go directly to content section">
				Skip to content
			</SkipToMain>
			<SearchCludo handleCloseSearch={handleCloseSearch} theme="def" />
			<Container width="Full width" className={styles.HeaderDef_container}>
				<Grid nogutter={true} center={true}>
					{/* <img src="/def_medlemsportal_logo.png" className={styles.new_logo_test} /> */}
					<Logo className={styles.HeaderDef_logo} withText={true} />
					<li className={classNames(styles.HeaderDef_serviceItem, styles.HeaderDef_serviceItem___primary)} onClick={searchClick}>
						<Link className={styles.HeaderDef_link}>
							<Icon className={styles.HeaderDef_iconLoop} size="md">
								<IconLoop />
							</Icon>
							<span className={styles.HeaderDef_title}>Søg</span>
						</Link>
					</li>

					<li className={classNames(styles.HeaderDef_serviceItem, styles.HeaderDef_serviceItem___secondary)}>
						<Button className={styles.HeaderDef_link} onClick={toggleShowLoginModal} style="primary">
							<Icon className={styles.HeaderDef_iconLock} size="md">
								<IconLock />
							</Icon>
							<span className={styles.HeaderDef_title}>Login</span>
						</Button>
					</li>

					{navigationItemsDef && (
						<div className={classNames(styles.HeaderDef_navigation)}>
							<input
								onChange={(event: any) => (event.target.checked ? setNavOpen(true) : setNavOpen(false))}
								className={styles.HeaderDef_navController}
								type="checkbox"
								id="nav-controller"
								data-ref="HeaderDef__navController"
								ref={checkboxRef}
							/>
							<label className={styles.HeaderDef_navToggle} htmlFor="nav-controller" aria-expanded={navOpen}>
								<span
									className={styles.HeaderDef_icon}
									role="navigation"
									aria-haspopup="true"
									aria-expanded={navOpen}
									tabIndex={0}
									onKeyDown={(e) => handleKeyboardNav(e)}
								>
									<span className={styles.HeaderDef_iconBar}></span>
									<span className={styles.HeaderDef_iconBar}></span>
									<span className={styles.HeaderDef_iconBar}></span>
									<span className={styles.HeaderDef_iconBar}></span>
								</span>
								<span className={styles.HeaderDef_navToggleText} aria-expanded={navOpen}>
									Menu
								</span>
								<span className={styles.HeaderDef_navToggleTextClose} aria-expanded={navOpen}>
									Luk
								</span>
							</label>
							<div className={classNames(styles.HeaderDef_nav, navOpen && styles.HeaderDef_nav___active)} data-ref="HeaderDef__navPanel">
								<Grid nogutter={true} wrap={true} className={styles.HeaderDef_navWrapper}>
									<GridCell className={styles.HeaderDef_navContainer}>
										<ServiceNavigation serviceNavigationItems={serviceNavigationItems} style="primary" />
										<NavigationDef
											heading={navHeading}
											navigationItemsDef={navigationItemsDef}
											style="primary"
											className={styles.HeaderDef_navigation}
											handleOpenSearch={handleOpenSearch}
										/>
									</GridCell>
									<GridCell className={styles.HeaderDef_memberContainer}>
										{languageBarItems && <LanguageBar languageBarItems={languageBarItems} languageBarTitle={languageBarTitle} />}
										{isLoggedIn
											? registerLink?.logOutLink && (
													<a className={styles.HeaderDef_memberLink} onClick={signOutClick}>
														{registerLink.logOutLink.name}
													</a>
											  )
											: registerLink?.registerLink && (
													<LinkButton className={styles.HeaderDef_memberLink} style="secondary" target="blank" url={registerLink.registerLink.url}>
														{registerLink.registerLink.name}
													</LinkButton>
											  )}
									</GridCell>
								</Grid>
							</div>
						</div>
					)}
				</Grid>
			</Container>
		</header>
	);
};
