import { PageContext } from 'application/adapters/context/PageContext';
import { getNews, NewsResponse } from 'application/repositories/newsRepository';
import { useContext } from 'react';
import useSWR from 'swr';

const getNewsFromRepository = async (
	rootId: number,
	limit: number,
	offset: number,
	preview: boolean,
	previewData: Models.PreviewData,
): Promise<NewsResponse> => {
	return getNews({ rootId, limit, offset, preview, previewData });
};

export const NewsFetcher = (rootId: number, limit: number, offset: number): Models.NewsCard[] => {
	const context = useContext(PageContext);

	const { data, error } = useSWR([rootId, limit, offset, context.preview, context.previewData], getNewsFromRepository);
	if (error) throw error;
	if (!data) return [];

	const mappedResults = data.articles.map((result): Models.NewsCard => {
		return {
			heading: result.heading,
			link: {
				url: result.url,
			},
			image: result.image,
			session: result.session,
			kicker: result.kicker,
			text: result.text,
			date: result.date,
			updatedDate: result.updatedDate,
		};
	});
	return mappedResults;
};
