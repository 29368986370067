import { AppStateContext } from 'application/adapters/appState/appState';
import classNames from 'classnames';
import { parallax } from 'helpers/parallax';
import React, { useContext, useEffect,useState } from 'react';
import { Button, Container, FormfieldString, Grid, GridCell, Heading,Spinner, Icon, Link, LinkButton, Paragraph, Picture } from 'ui/components';
import { ReactComponent as IconKey } from 'ui/icons/icon-key.svg';
import styles from './PaymentCompleted.module.scss';
import {RichText,Form } from 'ui/components';
import { useForm,SubmitHandler } from 'react-hook-form';
import { ReactComponent as Fail } from 'ui/icons/icon-fail.svg';
import { ArticleHeader, ArticleByline, ArticleAuthor } from 'ui/components/2-molecules/Article';



export interface PaymentCompletedProps {
	className?: string;
	completedtekst?: string;
	amount?: number;
	customerName?:string;
	greetingLine1?:string;
	greetingLine2?:string;

}

export const PaymentCompleted: React.FC<PaymentCompletedProps> = ({
	className,
	completedtekst,	
	amount,
	customerName,
	greetingLine1,
	greetingLine2


}) => {
	





	return (
		<>
		{!customerName ? (
				<Spinner />
			) : (
		<Container className={classNames(styles.PaymentCompleted, className)} width="Full width">
		<GridCell>
			<section className={styles.WelcomeCard_top}>
				<div className={styles.WelcomeCard_name}>
				<Heading headingLevel="h2" className={styles.PaymentCompleted_heading}>
						<span className={styles.WelcomeCard_greeting}>Kære {customerName}  </span>
					</Heading>
					<section>
					<p className={styles.PaymentCompleted_contentValue}>{completedtekst} {amount} DKK</p>
					</section>
					<section>
					<p className={styles.PaymentCompleted_greetingValue}>{greetingLine1} </p>
					</section>
					<section>
					<p className={styles.PaymentCompleted_greetingValue}>{greetingLine2} </p>
					</section>


				</div>

			</section>
		</GridCell>

	</Container>
			)}
	</>
		
	);
};
