import { ListLinks } from 'ui/components/3-organisms/List/ListLinks';
import { LinksProps } from 'ui/components/2-molecules/Links';
import { shouldShowContent } from 'helpers/audiencehelper';

export const ListLinksFeature: React.FC<Umbraco.ListLinks> = ({ content }) => {
	const show = shouldShowContent(content.properties.audiencePicker);
	const { heading, text, style, useBackground } = content?.properties ?? {};
	const { items } = content?.properties ?? {};

	const mappedItems: LinksProps[] = items.map(({ content }) => {
		const { link } = content?.properties ?? {};

		return {
			link,
		};
	});

	return show ? <ListLinks items={mappedItems} heading={heading} text={text} style={style} useBackground={useBackground} /> : <></>;
};
