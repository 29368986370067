import { useEffect, useState } from 'react';
import { getSignedUpArrangements, Arrangement } from 'application/repositories/arrangementsRepository';
import { getCMSHostname } from 'helpers/getCMSHostname';
import { ArrangementsBanner } from 'ui/components';
import logger from 'helpers/logger';

export const ArrangementsBannerSignedUpFeature: React.FC<Umbraco.ArrangementsBannerSignedUp> = ({ content }) => {
	const [arrangements, setArrangements] = useState<Arrangement[]>([]);
	const [hasLoaded, setHasLoaded] = useState<boolean>(false);
	const [hasError, setHasError] = useState<boolean>(false);

	useEffect(() => {
		getSignedUpArrangements(getCMSHostname())
			.then((arrangements) => {
				arrangements?.length > 0 && setArrangements(arrangements);
				setHasLoaded(true);
			})
			.catch((error) => {
				logger.error(error);
				setHasLoaded(true);
				setHasError(true);
			});
	}, []);

	return (
		<ArrangementsBanner
			hasError={hasError}
			hideComponent={hasLoaded && arrangements?.length < 1}
			arrangements={arrangements}
			{...content?.properties}
		/>
	);
};
