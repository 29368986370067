import { DictionaryContext } from 'application/adapters/context/Dictionary';
import { useContext } from 'react';
import { Search } from 'ui/components/3-organisms/Search';
import { SearchResultFetcher } from './SearchResultFetcher';

export const SearchFeature: React.FC = () => {
	const dictionary = useContext(DictionaryContext);

	return (
		<Search
			searchButtonText={dictionary.getValue('Search.Search')}
			searchInputPlaceholder={dictionary.getValue('Search.EnterKeyword')}
			requiredFieldErrorMessage={dictionary.getValue(
				'Search.RequiredField',
			)}
			searchResultFetcher={SearchResultFetcher}
			searchResultText={dictionary.getValue('Search.Results')}
			searchErrorText={dictionary.getValue(
				'Search.ErrorText',
				[],
				'Der er opstået en fejl i søgningen. Prøv venligst',
			)}
		/>
	);
};
