import { getTagBasedNews, getPersonalizedTagBasedNews } from 'application/repositories/tagBasedNewsRepository';
import { getCMSHostname } from 'helpers/getCMSHostname';
import { useEffect, useState } from 'react';
import { LatestNewsModule } from 'ui/components';
import { ProfileContext } from 'application/adapters/context/Profile';
import { useContext } from 'react';

/*
 *
 * This component is used on all sites except on kongres.def.dk
 *
 */

export const LatestNewsModuleFeature: React.FC<Umbraco.NewsModule> = ({ content }) => {
	const [news, setNews] = useState<Models.NewsCard[]>(null);
	useEffect(() => {
		const count = content?.properties?.count ? content.properties.count : 6;
		const tags = content?.properties?.tags ? content.properties.tags.map((tag) => tag.id) : null;
		const personalize = content?.properties?.personalize ?? false;

		if (personalize) {
			getPersonalizedTagBasedNews(getCMSHostname(), tags, count).then((news) => {
				setNews(news);
			});
			//	const availableAudiences = profileContext?.profile.;
		} else {
			// fetch news from api
			getTagBasedNews(getCMSHostname(), tags, count).then((news) => {
				setNews(news);
			});
		}
	}, []);

	return <LatestNewsModule {...content?.properties} newsFromApi={news} />;
};
