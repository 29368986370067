import { useContext } from 'react';
import { Header } from 'ui/components/4-habitats/Header';
import { SiteContext } from 'application/adapters/context/SiteContext';
import { PageContext } from 'application/adapters/context/PageContext';

export const HeaderFeature: React.FC = () => {
	const siteContext = useContext(SiteContext);
	const pageContext = useContext(PageContext);
	const navigationItems = siteContext.getNavigationItems(pageContext.id);
	const serviceNavigationItems = siteContext.getSettings('serviceNavigation');
	const registerLink = siteContext.getSettings('registerBar');

	return <Header navigationItems={navigationItems} serviceNavigationItems={serviceNavigationItems} registerLink={registerLink} />;
};
