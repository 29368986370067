import { AccordionFaq } from 'ui/components/3-organisms/AccordionFaq';
import { AccordionFaqItemProps } from 'ui/components/3-organisms/AccordionFaq/AccordionFaqItem';

export const AccordionFaqFeature: React.FC<Umbraco.AccordionFaq> = ({ content }) => {
	const { accordionItems } = content?.properties ?? {};

	const mappedAccordionItems: AccordionFaqItemProps[] = accordionItems.map(({ content, settings }) => {
		const { htmlContent, title } = content?.properties ?? {};
		const { openByDefault } = settings?.properties ?? {};

		return {
			htmlContent,
			title,
			openByDefault,
		};
	});

	return <AccordionFaq allowMultiple={false} {...content?.properties} accordionItems={mappedAccordionItems} />;
};
