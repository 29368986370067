import { ListSituation } from 'ui/components';

type ListSituationsFeatureProps = {
	documentType: 'listSituation';
	content: {
		documentType: 'listSituation';
		properties: {
			heading: string;
			situationItems: Umbraco.SituationCard[];
		};
	};
};
export const ListSituationFeature: React.VFC<ListSituationsFeatureProps> = ({ content }) => {
	return <ListSituation {...content.properties} />;
};
