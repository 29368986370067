import classNames from 'classnames';
import { useSession } from 'next-auth/react';
import { Profiles } from 'application/repositories/profilesRepository';
import { useEffect, useRef, useState } from 'react';

import {
	Container,
	Grid,
	GridCell,
	Icon,
	LanguageBar,
	Link,
	Button,
	Logo,
	NavigationMember,
	SearchCludo,
	ServiceNavigation,
	SkipToMain,
} from 'ui/components';
import { ReactComponent as IconLoop } from 'ui/icons/icon-loop.svg';
import { ReactComponent as IconPerson } from 'ui/icons/icon-person.svg';
import { signOut } from 'next-auth/react';
import styles from './HeaderMember.module.scss';

export interface HeaderMemberProps {
	className?: string;
	navHeading?: string;
	profiles: Profiles;
	serviceNavigationItems?: Models.ServiceNavigation;
	languageBarItems?: Models.LanguageBar;
	languageBarTitle?: Models.LanguageBar;
	navigationItemsMember?: Models.NavigationItem[];
	registerLink?: Models.RegisterBar;
	profileLink?: Models.ProfileBar;
}

export type navState = boolean;

export const HeaderMember: React.FC<HeaderMemberProps> = ({
	className,
	navHeading,
	navigationItemsMember,
	serviceNavigationItems,
	languageBarItems,
	languageBarTitle,
	registerLink,
	profileLink,
	profiles,
}) => {
	const [navOpen, setNavOpen] = useState<navState>(false);
	const [searchOpen, setSearchOpen] = useState(false);
	const checkboxRef = useRef(null);
	const headerRef = useRef(null);
	const { status, data } = useSession();
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	useEffect(() => {
		if (status === 'authenticated') {
			setIsLoggedIn(true);
		} else {
			setIsLoggedIn(false);
		}
	}, [status]);

	const searchClick = () => {
		setSearchOpen(!searchOpen);
	};
	const handleClickClose = (event) => {
		const element = headerRef.current;
		if (!element.contains(event.target)) {
			setSearchOpen(false);
		}
	};
	const handleCloseSearch = () => {
		setSearchOpen(false);
	};
	const handleKeyboardNav = (keyDownEvent) => {
		keyDownEvent.preventDefault();
		if (keyDownEvent.keyCode === 32) {
			if (!checkboxRef.current.checked) {
				checkboxRef.current.checked = true;
				setNavOpen(true);
			} else {
				checkboxRef.current.checked = false;
				setNavOpen(false);
			}
		}
	};
	useEffect(() => {
		if (searchOpen) {
			document.addEventListener('click', handleClickClose);
		}
		return () => document.removeEventListener('click', handleClickClose);
	}, [searchOpen]);

	const signOutClick = () => {
		signOut({
			redirect: false,
		}).then(() => {
			const redirectUrl = registerLink?.logOutLink?.url ?? window.location.href;
			window.location.href = `https://${process.env.NEXT_PUBLIC_AUTHENTICATION_DOMAIN}/account/logout?returnUrl=${redirectUrl}`;
		});
	};
	const filteredServiceNavigationItems = {
		serviceNav: !profiles?.canSeeUnionRepresentativeWebsite
			? serviceNavigationItems?.serviceNav.filter((item) => !item.content.properties.isUnionRepresentativeWebsiteLink)
			: serviceNavigationItems?.serviceNav,
	};

	return (
		<header id="header" ref={headerRef} className={classNames(styles.HeaderMember, className)} role="banner" aria-expanded={searchOpen}>
			<SkipToMain link="#main" title="Click to skip navigations and go directly to content section">
				Skip to content
			</SkipToMain>
			<Container width="Full width">
				<Grid nogutter={true} center={true}>
					<Logo className={styles.HeaderMember_logo} withText={true} />
					{isLoggedIn && <SearchCludo handleCloseSearch={handleCloseSearch} theme="member" />}
					<li className={classNames(styles.HeaderMember_serviceItem, styles.HeaderMember_serviceItem___primary)} onClick={searchClick}>
						<Link className={styles.HeaderMember_link}>
							<Icon className={styles.HeaderMember_iconLoop} size="md">
								<IconLoop />
							</Icon>
							<span className={styles.HeaderMember_title}>Søg</span>
						</Link>
					</li>
					{navigationItemsMember && (
						<div className={classNames(styles.HeaderMember_navigation)}>
							<input
								onChange={(event: any) => (event.target.checked ? setNavOpen(true) : setNavOpen(false))}
								className={styles.HeaderMember_navController}
								type="checkbox"
								id="nav-controller"
								data-ref="HeaderMember__navController"
								ref={checkboxRef}
							/>
							<label className={styles.HeaderMember_navToggle} htmlFor="nav-controller" aria-expanded={navOpen}>
								<span
									className={styles.HeaderMember_icon}
									role="navigation"
									aria-haspopup="true"
									aria-expanded={navOpen}
									tabIndex={0}
									onKeyDown={(e) => handleKeyboardNav(e)}
								>
									<span className={styles.HeaderMember_iconBar}></span>
									<span className={styles.HeaderMember_iconBar}></span>
									<span className={styles.HeaderMember_iconBar}></span>
									<span className={styles.HeaderMember_iconBar}></span>
								</span>
								<span className={styles.HeaderMember_navToggleText} aria-expanded={navOpen}>
									Menu
								</span>
								<span className={styles.HeaderMember_navToggleTextClose} aria-expanded={navOpen}>
									Luk
								</span>
							</label>
							<div className={classNames(styles.HeaderMember_nav, navOpen && styles.HeaderMember_nav___active)} data-ref="HeaderMember__navPanel">
								<Grid nogutter={true} wrap={true} className={styles.HeaderMember_navWrapper}>
									{isLoggedIn && (
										<>
											{filteredServiceNavigationItems && (
												<GridCell className={styles.HeaderMember_serviceNavContainer}>
													<ServiceNavigation serviceNavigationItems={filteredServiceNavigationItems} style="primary" />
													{profiles?.notificationCount > 0 && <span className={styles.HeaderMember_serviceMail}>{profiles?.notificationCount}</span>}
												</GridCell>
											)}
											{navigationItemsMember && (
												<GridCell className={styles.HeaderMember_navContainer}>
													<NavigationMember
														heading={navHeading}
														navigationItemsMember={navigationItemsMember}
														style="primary"
														className={styles.HeaderMember_navigation}
													/>
												</GridCell>
											)}
										</>
									)}
									<GridCell className={styles.HeaderMember_memberContainer}>
										{languageBarItems && <LanguageBar languageBarItems={languageBarItems} languageBarTitle={languageBarTitle} />}
										{isLoggedIn && (
											<>
												{data?.user?.name && (
													<aside className={styles.HeaderMember_profile}>
														<div className={styles.HeaderMember_profileImage}>
															<Icon className={styles.HeaderMember_iconPerson} size="md">
																<IconPerson />
															</Icon>
														</div>
														<div className={styles.HeaderMember_profileInfo}>
															<p className={styles.HeaderMember_profileName}>{data?.user?.name}</p>
															<Link className={styles.HeaderMember_profileLink} target="blank" url={profileLink?.profileLink?.url}>
																{profileLink?.profileLink?.name}
															</Link>
														</div>
													</aside>
												)}
												{registerLink?.logOutLink?.name && (
													<a className={styles.HeaderMember_memberLink} onClick={signOutClick}>
														{registerLink?.logOutLink?.name}
													</a>
												)}
											</>
										)}
									</GridCell>
								</Grid>
							</div>
						</div>
					)}
				</Grid>
			</Container>
		</header>
	);
};
