import { ListTimeline } from 'ui/components/3-organisms/List/ListTimeline';
import { TimelineProps } from 'ui/components/2-molecules/Timeline';

export const ListTimelineFeature: React.FC<Umbraco.ListTimeline> = ({ content }) => {
	const { heading, text, showMoreText, showLessText, amountToShow } = content?.properties ?? {};
	const { items } = content?.properties ?? {};

	const mappedItems: TimelineProps[] = items.map(({ content }) => {
		const { time, timelineText } = content?.properties ?? {};

		return {
			time,
			timelineText,
		};
	});

	return (
		<ListTimeline
			items={mappedItems}
			heading={heading}
			text={text}
			showMoreText={showMoreText}
			showLessText={showLessText}
			amountToShow={amountToShow}
		/>
	);
};
