import { ListMembers } from 'ui/components';
import { getCMSHostname } from 'helpers/getCMSHostname';
import React, { useEffect, useState } from 'react';
import { getMembers, Member } from 'application/repositories/membersRepository';
import logger from 'helpers/logger';

export interface ListMembers {
	documentType: 'listMembers';
	content: {
		documentType: 'listMembers';
		properties: {
			isDownloadable: boolean;
			filename: string;
			primaryLabel: string;
			secondaryLabel: string;
			items: []; // TODO: Remove?
		};
	};
}
export const ListMembersFeature: React.FC<ListMembers> = ({ content }) => {
	const { isDownloadable, filename, primaryLabel, secondaryLabel } = content.properties;

	const [members, setMembers] = useState<Member[]>(null);

	useEffect(() => {
		getMembers(getCMSHostname())
			.then((members) => {
				setMembers(members);
			})
			.catch((error) => {
				logger.error(error);
			});
	}, []);

	return (
		<ListMembers
			members={members}
			isDownloadable={isDownloadable}
			filename={filename}
			primaryLabel={primaryLabel}
			secondaryLabel={secondaryLabel}
		/>
	);
};
