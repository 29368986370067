import { NewsModule } from 'ui/components';
import { NewsFetcher } from './NewsFetcher';
/*
 *
 *
 * This component is only used on kongres.def.dk
 *
 *
 */

export const NewsModuleFeature: React.FC<Umbraco.NewsModule> = ({ content }) => <NewsModule {...content?.properties} newsFetcher={NewsFetcher} />;
