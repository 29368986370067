import Head from 'next/head';

export interface CookieConsentProps {
	culture: string;
}

export const CookieConsent: React.FC<CookieConsentProps> = (props) => {
	return (
		<Head>
			<script id="CookieConsent" src="https://policy.app.cookieinformation.com/uc.js"
    data-culture="DA" data-gcm-version="2.0" type="text/javascript"></script>
		</Head>
	);
};
