import axios from 'axios';
import { getAccessTokenBrowser } from 'helpers/auth';
import { getApiUrl } from './constants';

export type GetMembersResult = Array<{
	memberId?: string;
	name?: string;
	company?: string;
	branch?: string;
	pNumber?: string;
	job?: string;
	birthday?: string;
	employedOn?: string;
	isHighlighted?: boolean;
	phones?: Array<string>;
	emails?: Array<string>;
	privatePhones?: Array<string>;
	privateEmails?: Array<string>;
	chairedTitles?: Array<{
		chairedTitle: string;
		actingMemberPeriod: string; // TODO: Change name of actingMemberPeriod? The type is a date, but the date of what?
	}>;
	event?: {
		type: string;
		date: string;
	};
	linkedAgreements?: Array<{
		id: string;
		name: string;
		url?: string;
	}>;
}>;
export const getMembersAdapter = async (hostname: string): Promise<GetMembersResult> => {
	const accessToken = await getAccessTokenBrowser();
	const { href } = getApiUrl('Member/GetMembers');
	const { data: members } = await axios.get<GetMembersResult>(href, { headers: { hostname, Authorization: 'Bearer ' + accessToken } });
	return members;
};

export type GetMemberResult = {
	memberId: string;
	name?: string;
	company?: string;
	branch?: string;
	pNumber?: string;
	job?: string;
	birthday?: string;
	employedOn?: string;
	phones?: Array<string>;
	privateEmails?: Array<string>;
	privatePhones?: Array<string>;
	emails?: Array<string>;
	chairedTitles?: Array<{
		chairedTitle: string;
		actingMemberPeriod: string; // TODO: Change name of actingMemberPeriod? The type is a date, but the date of what?
	}>;
	event?: {
		type: string;
		date: string;
	};
	linkedAgreements?: Array<{
		id: string;
		name: string;
		url?: string;
	}>;
};
export type RequestHeaders = {
	hostname: string;
	Authorization?: string;
};
export const getMemberAdapter = async (hostname: string): Promise<GetMemberResult> => {
	const accessToken = await getAccessTokenBrowser();
	const headers: RequestHeaders = {
		hostname,
	};
	if (accessToken) {
		headers.Authorization = 'Bearer ' + accessToken;
	}
	const { href } = getApiUrl('Member/GetMember'); // TODO: Use correct URL.
	const { data: member } = await axios.get<GetMemberResult>(href, { headers: headers });
	return member;
};
