import { Main } from 'ui/components/4-habitats/Main';
import { ArticleHeader, ArticleByline, ArticleDate } from 'ui/components/2-molecules/Article';
import { Container } from 'ui/components/4-habitats/Container';
import { ModuleMapFeature } from 'features/Mappers/Module';
import styles from './NewsPage.module.scss';

export const NewsPageFeature: React.FC<Umbraco.NewsPage> = ({ properties }) => {
	const { newsTeaser, newsHeading, newsAuthor, newsDate, updatedNewsDate, pageSections } = properties ?? {};

	return (
		<Main>
			<Container width="Small">
				<ArticleHeader teaser={newsTeaser} heading={newsHeading} />
			</Container>
			<Container width="Small">
				{pageSections.map((pageSection, index) => (
					<ModuleMapFeature key={index} contentModule={pageSection} />
				))}
			</Container>
			<Container width="Small" className={styles.NewsPage_byline}>
				{newsAuthor && <ArticleByline {...newsAuthor?.properties} />}
				<ArticleDate date={newsDate} updatedDate={updatedNewsDate} />
			</Container>
		</Main>
	);
};
