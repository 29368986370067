import { GetAgreementFilesResult, getAllAgreementFiles } from 'application/adapters/agreements/agreementsAdapter';
import { getCMSHostname } from 'helpers/getCMSHostname';
import logger from 'helpers/logger';
import React, { useEffect, useState } from 'react';
import { ListAgreements } from 'ui/components';

export const ListAgreementsFeature: React.FC<Umbraco.ListAgreements> = ({ content }) => {
	const [agreements, setAgreements] = useState<GetAgreementFilesResult>(null);

	useEffect(() => {
		getAllAgreementFiles(getCMSHostname())
			.then((response) => {
				setAgreements(response.data);
			})
			.catch((error) => {
				logger.error(error);
			});
	}, []);

	return <ListAgreements {...content?.properties} agreements={agreements} />;
};
