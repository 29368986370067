import { checkIfSSR } from 'helpers/checkIfSSR';
import { signIn } from 'next-auth/react';
import { ArticleText, Button } from 'ui/components';

export const LoginFeature: React.FC<Umbraco.ArticleText> = ({ content }) => {
	let callBackUrl = process.env.NEXTAUTH_URL;
	!checkIfSSR && (callBackUrl = window.location.origin);

	return (
		<div className="u-top-margin--xxxl u-bottom-margin--xxxl u-left-padding--sm u-right-padding--sm">
			<div className="u-bottom-margin--xl">
				<ArticleText {...content?.properties} />
			</div>
			<Button onClick={() => signIn('authServer', { callBackUrl: callBackUrl })}>Log ind</Button>
		</div>
	);
};
