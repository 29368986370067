import logger from 'helpers/logger';
import { ErrorBoundary } from 'react-error-boundary';
import { capitalizeFirstLetter } from 'helpers/string';
import React from 'react';
import * as Modules from 'features/Modules';

export interface ModuleMapFeatureProps {
	contentModule: Umbraco.ContentModule;
	width?: Umbraco.SectionWidth;
}

export const ModuleMapFeature: React.FC<ModuleMapFeatureProps> = ({ contentModule,width }) => {
	const production = process.env.NODE_ENV === 'production';

	const { documentType } = contentModule;

	const moduleName = `${capitalizeFirstLetter(documentType)}Feature`;

	const DynamicModule = Modules[moduleName];

	const message = `Component [${documentType}] is missing a React Component. Add missing export '${moduleName}' from '/features`;

	if (!DynamicModule && !production) {
		return <div>{message}</div>;
	}

	if (!DynamicModule && production) {
		logger.error(message);
		return null;
	}

	const errorHandler = (error: Error, info: { componentStack: string }) => {
		logger.error(`Error with component [${documentType}]: ${error}. ${info.componentStack}`);
	};

	return (
		<ErrorBoundary FallbackComponent={() => null} onError={errorHandler}>
			<DynamicModule {...contentModule}  />
		</ErrorBoundary>
	);
};
