const logger = {
	/**
	 * Logs information
	 *
	 * @param message The message to log
	 */
	info: (message: string): void => {
		console.log(message);
	},

	/**
	 * Logs a warning
	 *
	 * @param message The message to log
	 */
	warn: (message: string): void => {
		console.warn(message);
	},

	/**
	 * Logs a debug message
	 *
	 * @param message The message to log
	 */
	debug: (message: string): void => {
		console.warn(message);
	},

	/**
	 * Logs an error
	 *
	 * @param err The error message
	 */
	error: (error: string): void => {
		console.error(error);
	},

	/**
	 * Logs an error and flushes it to the log immediately
	 *
	 * @param err The error message
	 */
	errorAsync: async (error: string): Promise<void> => {
		console.error(error);
	},
};

export default logger;
