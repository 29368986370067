import { PageContext } from 'application/adapters/context/PageContext';
import { SiteContext } from 'application/adapters/context/SiteContext';
import { useContext } from 'react';
import { HeaderDef } from 'ui/components/4-habitats/HeaderDef';

export const HeaderDefFeature: React.FC = () => {
	const siteContext = useContext(SiteContext);
	const pageContext = useContext(PageContext);
	const navigationItemsDef = siteContext.getNavigationItems(pageContext.id);
	const serviceNavigationItems = siteContext.getSettings('serviceNavigation');
	const languageBarItems = siteContext.getSettings('languageBar');
	const languageBarTitle = siteContext.getSettings('languageBar');
	const registerLink = siteContext.getSettings('registerBar');
	return (
		<HeaderDef
			navigationItemsDef={navigationItemsDef}
			serviceNavigationItems={serviceNavigationItems}
			languageBarItems={languageBarItems}
			languageBarTitle={languageBarTitle}
			registerLink={registerLink}
		/>
	);
};
