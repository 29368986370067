import { ListShortcut } from 'ui/components/3-organisms/List/ListShortcut';
import { ShortcutCardProps } from 'ui/components/2-molecules/ShortcutCard';

export const ListShortcutFeature: React.FC<Umbraco.ListShortcut> = ({ content }) => {
	const { items } = content?.properties ?? {};

	const mappedItems: ShortcutCardProps[] = items.map(({ content }) => {
		const { heading, text, moduleLink } = content?.properties ?? {};

		return {
			heading,
			text,
			moduleLink,
		};
	});

	return <ListShortcut items={mappedItems} />;
};
