import { ThumbnailList } from 'ui/components/3-organisms/ThumbnailList';
import { ThumbnailCardProps } from 'ui/components/2-molecules/Card/ThumbnailCard';

export const ThumbnailListFeature: React.FC<Umbraco.ThumbnailList> = ({ content }) => {
	const { items, thumbnailListHeading, thumbnailListSubHeading, moduleLink } = content?.properties ?? {};

	const mappedItems: ThumbnailCardProps[] = items.map(({ content }) => {
		const { heading, image, text, link } = content?.properties ?? {};

		return {
			heading,
			image,
			text,
			link,
			moduleLink,
			columnWidth: '25',
			imageSizes: '(max-width: 768px) 50vw, 50vw',
		};
	});

	return (
		<ThumbnailList
			thumbnailListHeading={thumbnailListHeading}
			thumbnailListSubHeading={thumbnailListSubHeading}
			moduleLink={moduleLink}
			columns={mappedItems.length + 1}
			items={mappedItems}
		/>
	);
};
