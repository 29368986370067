import { Container } from 'ui/components/4-habitats/Container';
import { ModuleMapFeature } from 'features/Mappers/Module';

export const GlobalArticleContentFeature: React.FC<Umbraco.GlobalArticleContent> = ({ properties, globalArticleWidth }) => {
	const { globalArticlePageSections } = properties ?? {};

	return (
		<Container width={globalArticleWidth === '' ? 'Small' : globalArticleWidth}>
			{globalArticlePageSections?.map((sectionModule, index) => (
				<ModuleMapFeature key={index} contentModule={sectionModule} />
			))}
		</Container>
	);
};
