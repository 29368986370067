import { postFeedback } from 'application/adapters/feedback/feedbackAdapter';
import { getCMSHostname } from 'helpers/getCMSHostname';
import logger from 'helpers/logger';
import { useState } from 'react';
import { Feedback } from 'ui/components/3-organisms/Feedback';

export const FeedbackFeature: React.FC<Umbraco.Feedback> = ({ content }) => {
	const [isSuccess, setSuccess] = useState(false);
	const [isFail, setFail] = useState(false);
	const [isLoading, setLoading] = useState(true);

	const submitForm = (data) => {
		setLoading(false);
		postFeedback(getCMSHostname(), data)
			.then((response) => {
				if (response.status == 200) {
					setTimeout(() => {
						setSuccess(!isSuccess);
					}, 50);
				}
			})
			.catch((error) => {
				setFail(!isFail);
				logger.error(error);
			})
			.finally(() => {
				setLoading(true);
			});
	};
	return <Feedback {...content?.properties} isSuccess={isSuccess} isFail={isFail} onClick={submitForm} isLoading={isLoading} />;
};
