import { Meta } from './Meta';
import { HeaderFeature } from '../Header';
import { HeaderPortalFeature } from '../HeaderPortal';
import { HeaderDefFeature } from '../HeaderDef';
import { HeaderMemberFeature } from '../HeaderMember';
import { LockedFooterFeature } from '../LockedFooter';
import { PageContentResponse } from 'application/repositories/pageContentRepository';
import { useContext } from 'react';
import { PageContext } from 'application/adapters/context/PageContext';
import { SiteContext } from 'application/adapters/context/SiteContext';
import { RegisterOverlay } from 'ui/components/3-organisms/RegisterOverlay/RegisterOverlay';

export const LayoutFeature: React.FC<PageContentResponse> = ({ children, page, footer, siteTheme = [] }) => {
	const { pageFooter } = footer;
	const { primaryColor, secondaryColor, tertiaryColor } = siteTheme[0]?.content?.properties?.theme?.properties ?? {};
	const themeStyle = {
		'--color-primary': primaryColor,
		'--color-secondary': secondaryColor,
		'--color-tertiary': tertiaryColor,
	} as React.CSSProperties;
	const theme = useContext(PageContext).pageType.siteTheme?.[0]?.content?.properties?.theme?.name;
	const defaultTheme = 'theme-kongres';
	const portalTheme = 'theme-tillidsportal';
	const defTheme = 'theme-def';
	const medlemTheme = 'theme-medlem';

	let header;
	if (theme === defaultTheme) {
		header = <HeaderFeature />;
	} else if (theme === portalTheme) {
		header = <HeaderPortalFeature />;
	} else if (theme === defTheme) {
		header = <HeaderDefFeature />;
	} else if (theme === medlemTheme) {
		header = <HeaderMemberFeature />;
	}

	const siteContext = useContext(SiteContext);
	const registerOverlayTitle = siteContext.getSettings('registerOverlay');
	const registerOverlayLinks = siteContext.getSettings('registerOverlay');

	return (
		<div className={theme} style={themeStyle}>
			<Meta {...page?.properties} />
			{header}
			{children}
			{registerOverlayLinks && <RegisterOverlay registerOverlayTitle={registerOverlayTitle} registerOverlayLinks={registerOverlayLinks} />}
			<LockedFooterFeature footerItems={pageFooter} />
		</div>
	);
};
