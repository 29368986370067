export class ApiError extends Error {
	public statusCode: number;
	public url: number;

	constructor(name: string, statusCode: number, message?: string) {
		super(message);
		this.name = name;
		this.statusCode = statusCode;
	}
}

export class ApiRedirect extends Error {
	public statusCode: number;
	public url: string;

	constructor(statusCode: number, url: string) {
		super();
		this.statusCode = statusCode;
		this.url = url;
	}
}

export class ApiSecurityError extends Error {
	public statusCode: number;
	public url: string;
	public content: string;

	constructor(name: string, statusCode: number, url: string, content: string) {
		super();
		this.statusCode = statusCode;
		this.url = url;
		this.content = content;
	}
}
