import { getArrangements, Arrangement } from 'application/repositories/arrangementsRepository';
import { getCMSHostname } from 'helpers/getCMSHostname';
import logger from 'helpers/logger';
import { useEffect, useState } from 'react';
import { ArrangementsBanner } from 'ui/components';

export const ArrangementsBannerFeature: React.FC<Umbraco.ArrangementsBanner> = ({ content }) => {
	const [arrangements, setArrangements] = useState<Arrangement[]>([]);
	const [hasError, setHasError] = useState<boolean>(false);
	const personalize = content?.properties?.personalize ?? false;
	useEffect(() => {
		getArrangements(getCMSHostname(), personalize)
			.then((arrangements) => {
				arrangements?.length > 0 && setArrangements(arrangements.slice(0, 3));
			})
			.catch((error) => {
				logger.error(error);
				setHasError(true);
			});
	}, []);

	return <ArrangementsBanner hasError={hasError} arrangements={arrangements} {...content?.properties} />;
};
