import { searchAdapter } from 'application/adapters/search/searchAdapter';

export interface SearchResultResponse {
	numberOfResults: number;
	query: string;
	searchResults: Models.SearchResult[];
}

export const getSearchResults = (query: string): Promise<SearchResultResponse> => {
	return searchAdapter({ query });
};

