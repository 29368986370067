import { Main } from 'ui/components/4-habitats/Main';
import { ArticleHeader, ArticleByline, ArticleAuthor } from 'ui/components/2-molecules/Article';
import { Container } from 'ui/components/4-habitats/Container';
import { ModuleMapFeature } from 'features/Mappers/Module';

export const ArticlePageFeature: React.FC<Umbraco.ArticlePage> = ({ properties }) => {
	const { author, pageSections } = properties ?? {};

	return (
		<Main>
			<Container width="Small">
				<ArticleHeader {...properties} />
				{author && <ArticleByline {...author?.properties} />}
			</Container>
			{pageSections.map((pageSection, index) => (
				<ModuleMapFeature key={index} contentModule={pageSection} />
			))}
			<Container width="Small">{author && <ArticleAuthor {...author?.properties} />}</Container>
		</Main>
	);
};
