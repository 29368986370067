import { AppStateContext } from 'application/adapters/appState/appState';
import classNames from 'classnames';
import React, { useContext, useEffect } from 'react';
import { Modal, ShortcutCard } from 'ui/components';
import styles from './RegisterOverlay.module.scss';

export interface RegisterOverlayProps {
	className?: string;
	registerOverlay?: Models.RegisterOverlay;
	registerOverlayTitle?: Models.RegisterOverlay;
	registerOverlayLinks?: Models.RegisterOverlay;
}

export const RegisterOverlay: React.FC<RegisterOverlayProps> = ({ className, registerOverlayTitle, registerOverlayLinks }) => {
	const { showLoginModal, toggleShowLoginModal } = useContext(AppStateContext);

	// const [isOpen, setIsOpen] = useState(false);
	const closeEscape = (event) => {
		if (event.keyCode === 27) {
			toggleShowLoginModal;
		}
	};
	useEffect(() => {
		document.addEventListener('keydown', closeEscape);

		return () => {
			document.removeEventListener('keydown', closeEscape);
		};
	}, []);

	return (
		<>
			{showLoginModal && (
				<Modal
					className={styles.RegisterOverlay}
					handleClose={toggleShowLoginModal}
					title={registerOverlayTitle.registerOverlayTitle}
					ariaClose="close"
				>
					<article className={styles.RegisterOverlay_linkWrapper}>
						{registerOverlayLinks.registerOverlayLinks.map((args, index) => {
							const { heading, text, moduleLink } = args?.content?.properties ?? {};
							return (
								<ShortcutCard
									key={index}
									heading={heading}
									text={text}
									moduleLink={moduleLink}
									{...args}
									className={classNames(styles.RegisterOverlay_linkItems, 'Register_overlayLinkItems')}
								/>
							);
						})}
					</article>
				</Modal>
			)}
		</>
	);
};
