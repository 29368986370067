import { Main } from 'ui/components';
import { ArticleHeader, ArticleByline, ArticleDate } from 'ui/components/2-molecules/Article';
import { ModuleMapFeature } from 'features/Mappers/Module';
import { Container } from 'ui/components/4-habitats/Container';
import styles from './GlobalNewsPage.module.scss';

export const GlobalNewsPageFeature: React.FC<Umbraco.GlobalNewsPage> = ({ properties }) => {
	const { pageHeader, pageSections, newsTeaser, newsHeading, newsAuthor, newsDate, updatedNewsDate } = properties ?? {};

	return (
		<Main
			header={pageHeader?.map((headerModule, index) => (
				<ModuleMapFeature key={index} contentModule={headerModule} />
			))}
		>
			<Container width="Small">
				<ArticleHeader teaser={newsTeaser} heading={newsHeading} />
			</Container>
			<Container width="Small">
				{pageSections?.map((sectionModule, index) => (
					<ModuleMapFeature key={index} contentModule={sectionModule} />
				))}
			</Container>
			<Container width="Small" className={styles.GlobalNewsPage_byline}>
				{newsAuthor && <ArticleByline {...newsAuthor?.properties} />}
				<ArticleDate date={newsDate} updatedDate={updatedNewsDate} />
			</Container>
		</Main>
	);
};
