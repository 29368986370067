import { BranchContactInfo } from 'ui/components/2-molecules/Card/BranchContactInfo/BranchContactInfo';

export const BranchContactInfoFeature: React.FC<Umbraco.BranchContactInfo> = ({ content }) => {
	const { properties } = content?.properties.branch ?? {};
	const flatPhoneNumbers = properties?.phoneNumbers?.map((phone) => phone.content.properties);
	const flatPhoneOpeningHours = properties?.phoneOpeningHours?.map((phoneOpeningHour) => phoneOpeningHour.content.properties);
	const flatOpeningHours = properties?.openingHours?.map((openingHour) => openingHour.content.properties);

	return (
		<BranchContactInfo
			{...content?.properties}
			branchName={properties.branchName}
			address={properties.address}
			phoneNumbers={flatPhoneNumbers}
			phoneOpeningHours={flatPhoneOpeningHours}
			openingHours={flatOpeningHours}
			email={properties.email}
			shortAddress={properties.shortAddress}
			branchId={properties.branchId}
		/>
	);
};
