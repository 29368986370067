import { PageContentResponse } from 'application/repositories/pageContentRepository';
import { ModuleMapFeature } from 'features/Mappers/Module';
// this component is used in the LayoutFeature component, and is intended to render the footerDefFeature
export const LockedFooterFeature: React.FC<{
	footerItems: PageContentResponse['footer']['pageFooter'];
}> = ({ footerItems }) => {
	return (
		<>
			{footerItems?.map((footerItem, index) => (
				<ModuleMapFeature key={index} contentModule={footerItem} />
			))}
		</>
	);
};
