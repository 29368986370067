import { ArticleTextNews } from 'ui/components/2-molecules/Article';
import { PageContext } from 'application/adapters/context/PageContext';
import React, { useContext, useEffect, useState } from 'react';

export const ArticleTextNewsFeature: React.FC<Umbraco.ArticleTextNews> = ({ content }) => {
	const [currentUrl, setcurrentUrl] = useState('');
	const pageContext = useContext(PageContext);
	const openGraph = pageContext?.getSettings('openGraph');
	const ogTitle = openGraph?.title;

	useEffect(() => {
		setcurrentUrl(document.location.href);
	}, []);
	return <ArticleTextNews ogTitle={ogTitle} currentUrl={currentUrl} {...content?.properties} />;
};
