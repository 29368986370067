import { Main } from 'ui/components';

import { ModuleMapFeature } from 'features/Mappers/Module';
import { GlobalArticleContentFeature } from 'features/Modules';

export const GlobalArticlePagePageFeature: React.FC<Umbraco.GlobalArticlePage> = ({ properties }) => {
	const { pageHeader, pageSections, globalArticleContent, globalArticleWidth } = properties ?? {};

	const globalArticleObj = {...globalArticleContent, globalArticleWidth};

	return (
		<Main
			header={pageHeader?.map((headerModule, index) => (
				<ModuleMapFeature key={index} contentModule={headerModule} />
			))}
			className="GlobalArticlePage"
			width={!globalArticleWidth ? "Small" : globalArticleWidth}
		>
			<GlobalArticleContentFeature {...globalArticleObj} />
			{pageSections && pageSections?.map((sectionModule, index) => <ModuleMapFeature key={index} contentModule={sectionModule} width={globalArticleWidth}/>)}
		</Main>
	);
};
