import { Member, getMember, getMembers } from 'application/repositories/membersRepository';
import { getCMSHostname } from 'helpers/getCMSHostname';
import { useState, useEffect } from 'react';
import { PageContext } from 'application/adapters/context/PageContext';
import { SiteContext } from 'application/adapters/context/SiteContext';
import { useContext } from 'react';
import { HeaderMember } from 'ui/components/4-habitats/HeaderMember';
import { Profiles } from 'application/repositories/profilesRepository';
import logger from 'helpers/logger';
import { ProfileContext } from 'application/adapters/context/Profile';

export const HeaderMemberFeature: React.FC = () => {
	const siteContext = useContext(SiteContext);
	const pageContext = useContext(PageContext);
	const serviceNavigationItems = siteContext.getSettings('serviceNavigation');
	const languageBarItems = siteContext.getSettings('languageBar');
	const languageBarTitle = siteContext.getSettings('languageBar');
	const registerLink = siteContext.getSettings('registerBar');
	const profileLink = siteContext.getSettings('profileBar');
	const navigationItemsMember = siteContext.getNavigationItems(pageContext.id);
	const [profiles, setProfiles] = useState<Profiles>(null);

	const profileContext = useContext(ProfileContext);

	const profile = profileContext.profile
	useEffect(() => {
		if (profile) {
			setProfiles(profile);
		}
	}, [profile]);

	return (
		<HeaderMember
			navigationItemsMember={navigationItemsMember}
			serviceNavigationItems={serviceNavigationItems}
			languageBarItems={languageBarItems}
			languageBarTitle={languageBarTitle}
			registerLink={registerLink}
			profileLink={profileLink}
			profiles={profiles}
		/>
	);
};
