import { ProfileContext } from 'application/adapters/context/Profile';
import { useContext } from 'react';

/**
 *
 * @param audiencePicker, the audiencepicker from umbrac
 * @returns 1 boolean indicating whether the content should be displayed,
 */

export const shouldShowContent = (audiencePicker: Umbraco.AudiencePicker[]): boolean => {
	// Check if audiencePicker exists and has elements
	if (!audiencePicker || audiencePicker.length === 0) return true;

	const profileContext = useContext(ProfileContext);

	// Extract audiences from profile context, if available
	const availableAudiences = profileContext?.audiences;

	if (!availableAudiences) return false;

	// Determine if there is an overlapping audience that is not excluded
	const overlappingAudience = getOverlappingAudience(audiencePicker, availableAudiences);
	const shouldShow = overlappingAudience && (!overlappingAudience.content?.properties?.Exclude ?? false);

	return shouldShow;
};

const getOverlappingAudience = (
	audiencePicker: Umbraco.AudiencePicker[],
	audiencesForUser: { audiences: { name: string; id: string }[] },
): Umbraco.AudiencePicker | undefined => {
	if (!audiencePicker || audiencePicker.length === 0) return undefined;

	return audiencePicker?.find((cMSAudience) =>
		audiencesForUser.audiences.some(
			(oRttoAudiece) =>
				oRttoAudiece.name.toLowerCase().trim() === cMSAudience?.content?.properties?.link?.properties?.audienceName.toLowerCase().trim(),
		),
	);
};
