import { Member, getMember, getMembers } from 'application/repositories/membersRepository';
import { getCMSHostname } from 'helpers/getCMSHostname';
import { useState, useEffect } from 'react';
import { WelcomeCard } from 'ui/components/2-molecules/Card/WelcomeCard';

export const WelcomeCardFeature: React.FC<Umbraco.WelcomeCard> = ({ content }) => {
	const [member, setMember] = useState<Member>(null);
	const [members, setMembers] = useState<Member[]>(null);

	useEffect(() => {
		getMember(getCMSHostname())
			.then((member) => {
				setMember(member);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);
	useEffect(() => {
		getMembers(getCMSHostname())
			.then((members) => {
				setMembers(members);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return <WelcomeCard {...content?.properties} member={member} members={members} />;
};
